.features-section {
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-color: #E4DDD7;
}
.features-section .container {
  margin: 0 auto;
  text-align: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px; 
  box-sizing: border-box;
  overflow: hidden;
  /* background-color: #5195E9; */
}
@media (min-width: 768px) {
  .features-section {
    min-width: 1440px;
  }
  .features-section .container {
    padding: 200px 0 120px; 
    width: 1440px;
  }
}
@media (max-width: 768px) {
  .features-section .container {
    width: 100%;
  }
}

.jobs-support-title {
  color: #49454F;
  font-size: 50px;
  font-weight: 700;
}
.jobs-support-image {
  display: block;
  max-width: 100%;
  box-sizing: border-box;
  height: auto;
  display: block;
  margin: 0 auto; 
  margin-top: 3%;
}
.jobs-support-desc-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 20px;
  padding-top: 2%;
}
.jobs-support-demo-desc {
  color: #470B14; 
  font-size: 28px;
}
.jobs-support-logo-image {
  width: 100px;
  height: auto;
  position: relative;
  top: -10px;
}


.resume-title {
  color: #49454F;
  font-size: 50px;
  font-weight: 700;
  margin-top: 150px;
  margin-bottom: 20px;
}
.resume-image {
  width: 100%; 
  max-width: 100%; 
  height: auto;
  margin: 0 auto; 
  position: relative;
  margin-top: 2%;
}
.resume-desc {
  color: #470B14; 
  font-size: 28px;
  margin: 0;
}
/* 移动端 */
@media (max-width: 768px) {
  .resume-container {
    display: none;
  }
}