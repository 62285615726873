.about-section {
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-color: #E4DDD7;
}
.about-section .container {
  margin: 0 auto;
  text-align: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px; 
  box-sizing: border-box;
  /* background-color: #5195E9; */
}
@media (min-width: 768px) {
  .about-section {
    min-width: 1440px;
  }
  .about-section .container {
    width: 1440px;
  }
}
@media (max-width: 768px) {
  .about-section .container {
    width: 100%;
  }
}

.about-title {
  text-align: center;
  color: #49454F;
  font-weight: 700;
  margin-bottom: 40px;
}
.about-description {
  line-height: 2;
  color: #470B14;
  margin-bottom: 40px;
}
.about-contact-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
  justify-items: center;
  justify-content: center;
  padding: 0 20px;
}
.about-contact-item {
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}
.about-qrcode {
  width: 100%;
  height: auto;
  max-width: 300px;
}
.about-contact {
  margin-top: 20px;
  color: #470B14;
}
.about-footer {
  color: black;
  font-size: clamp(12px, 2vw, 16px);
}
.about-footer a {
  color: #5195E9;
  text-decoration: none;
}
.about-footer a:hover {
  text-decoration: underline;
}
.about-footer-beian {
  color: black !important;
}
.about-footer-beian :hover {
  text-decoration: underline;
}

/* PC 端样式 */
@media (min-width: 768px) {
  .about-section {
    padding-top: 5vw;
    padding-bottom: 8%;
    height: 30vw;
    max-height: 800px;
  }
  .about-title {
    font-size: 50px;
  }
  .about-description {
    font-size: 30px;
    margin-bottom: 8vh;
  }
  .about-contact-row {
    gap: 40px;
  }
  .about-contact {
    font-size: 30px;
    margin-top: 1%;
  }
  .about-footer {
    margin-top: 7%;
    padding-bottom: 20px;
  }
}

/* 移动端样式 */
@media (max-width: 768px) {
  .about-section {
    padding-top: 5vw;
    padding-bottom: 15vw;
  }
  .about-title {
    font-size: 30px;
  }
  .about-description {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .about-contact-row {
    gap: 20px;
  }
  .about-qrcode {
    max-width: 150px;
  }
  .about-contact {
    font-size: 16px;
    margin-top: 7px;
  }
  .about-footer {
    margin-top: 20vw;
  }
}