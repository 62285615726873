
.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100vw;
  padding-top: 2vw;
  padding-bottom: 5vw;
}
.section-title {
  color: #49454F;
  font-size: 50px;
  font-weight: 700;
}
.section-desc {
  color: #470B14; 
  font-size: 28px;
  padding-top: 2%;
}


.download-button {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DA771C;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  user-select: none;
  white-space: nowrap; 
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .download-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/download_hover.png');
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out; 
  }
  .download-button:hover::after {
    opacity: 1;
  }
  .download-button.hovered {
    background-image: url('../assets/download_hover.png');
  }
}