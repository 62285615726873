.topbar {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-width: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: #E4DDD7;
  overflow-x: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #B5B5B5;
  z-index: 1000;
}
.topbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .topbar .container {
    padding: 0 20px; 
    width: 100vw;
    max-width: 1440px;
  }
}
@media (max-width: 768px) {
  .topbar .container {
    width: 100%;
  }
}
.topbar-left {
  display: flex;
  align-items: flex-start;
  padding: 0;
}
.topbar-right {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0;
  white-space: nowrap;
}
.topbar-right a {
  color: #7190B8;
  text-decoration: none;
}
.topbar-right a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.topbar-download-button {
  font-size: 20px;
  margin-left: 10px;
  width: 145px;
  height: 45px;
  line-height: 1px;
}
@media (min-width: 769px) {
  /* pc */
  .topbar {
    height: 70px;
    padding: 0 20vw;
  }
  .topbar-logo {
    width: auto;
    height: 50px;
  }
  .topbar-right {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  /* mobile */
  .topbar {
    height: 50px;
    padding: 0 5vw;
  }
  .topbar-logo {
    width: auto;
    height: 30px;
  }
  .topbar-right {
    font-size: 14px;
  }
}


.contact-popup-overlay {
  position: fixed;
  display: flex;
  background: transparent;
  max-width: 65%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-popup-container {
  background: #fff;
  border-radius: 8px;
  padding: 15px 30px 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  justify-content: center;
  transition: opacity 0.1s ease, transform 0.1s ease;
  opacity: 0;
  transform: translateY(-20px) scale(0.9);
}
.contact-popup-container.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.contact-popup-container.hide {
  opacity: 0;
  transform: translateY(-20px) scale(0.9);
}
.contact-popup-content {
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.contact-popup-item {
  text-align: center;
  margin: 10px;
}
.contact-popup-item h3 {
  font-size: clamp(16px, 2.5vw, 20px); 
  margin-bottom: 10px;
  color: #470b14; 
}
.contact-popup-image {
  width: 150px;
  height: 150px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-popup-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
.contact-popup-image-placeholder {
  width: 100%;
  height: 100%;
  background: #ccc;
}



.download-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.download-popup-container {
  position: relative;
  background: white;
  border-radius: 10px; 
  overflow: hidden; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  max-width: 60vw; 
  max-height: 40vw; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-popup-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.download-popup-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: #49454F;
}
.download-popup-close-button:hover {
  color: black;
}
