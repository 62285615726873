.jobs-support-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100vw;
  padding-top: 2vw;
  padding-bottom: 5vw;
}
.jobs-support-mobile-title {
  color: #49454F;
  font-weight: 700;
  font-size: clamp(25px, 1px, 30px);
  margin-bottom: 10vw;
  white-space: nowrap;
}
.jobs-support-mobile-image {
  display: block;
  margin: 0 auto; 
  position: relative;
  margin-top: 3%;
  max-width: 75%;
}


.resume-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100vw;
  padding-top: 2vw;
  padding-bottom: 10vh;
}
.resume-mobile-desc {
  color: #470B14; 
  font-size: 16px;
  text-align: left;
  padding-left: 25%;
}

.features-mobile-desc {
  color: #470B14; 
  font-size: 16px;
  margin-top: 10%;
}
.features-mobile-image-left {
  display: block;
  position: relative;
  margin-top: 2%;
  margin-left: 0;
  width: 70%;
  height: auto;
}
.features-mobile-image-right {
  display: block;
  position: relative;
  margin-top: 2%;
  margin-left: auto; 
  margin-right: 0; 
  width: 70%;
  height: auto;
}
.features-mobile-image-left-mask {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
}
.features-mobile-image-right-mask {
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
  -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
}