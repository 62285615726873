.meeting-section {
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-color: white;
}
.meeting-section .container {
  margin: 0 auto;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  /* background-color: #5195E9; */
}
@media (min-width: 768px) {
  .meeting-section {
    min-width: 1440px;
  }
  .meeting-section .container {
    padding: 120px 0 200px 0; 
    width: 1440px;
  }
}
@media (max-width: 768px) {
  .meeting-section .container {
    padding: 30px 0; 
    width: 100%;
  }
}


.meeting-support-title {
  width: 100%;
  color: #49454F;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 80px;
}
.meeting-apps-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  /* background-color: red; */
}
.meeting-apps-image-pc {
  display: block;
  width: 100%; 
  height: auto; 
  object-fit: contain; 
  user-select: none;
}
.meeting-apps-image-mobile {
  display: none;
  user-select: none;
}
/* 移动端 */
@media (max-width: 768px) {
  .meeting-support-title {
    font-size: clamp(25px, 1px, 30px);
    margin-bottom: 10vw;
    white-space: nowrap;
  }
  .meeting-section {
    width: 100vw;
    height: 116vw;
  }
  .meeting-apps-image-pc {
    display: none;
  }
  .meeting-apps-image-mobile {
    display: block; 
    width: 80%; 
    height: auto; 
  }
}