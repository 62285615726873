.faq-section {
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-color: white;
}
.faq-section .container {
  margin: 0 auto;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px; 
  box-sizing: border-box;
  /* background-color: #5195E9; */
}
@media (min-width: 768px) {
  .faq-section {
    min-width: 1440px;
  }
  .faq-section .container {
    width: 1440px;
  }
}
@media (max-width: 768px) {
  .faq-section .container {
    width: 100%;
  }
}

.faq-title {
  text-align: center;
  color: #49454F;
  font-weight: 700;
}
.faq-item {
  padding: 0;
  flex-shrink: 0;
  text-align: justify;
  box-sizing: border-box;
  text-align: left;
  word-wrap: break-word; 
  word-break: break-word; 
  /* background-color: aqua; */
}
.faq-question {
  color: #470B14;
  margin-bottom: 10px;
  text-align: left;
}
.faq-answer {
  color: #848484;
  line-height: 1.6;
}
/* PC */
@media (min-width: 768px) {
  .faq-section {
    padding-top: 5vw;
    padding-bottom: 8vw;
  }
  .faq-title {
    font-size: 50px;
    margin-bottom: 40px;
  }
  .faq-item {
    padding-top: 30px;
  }
  .faq-question {
    font-size: 30px;
  }
  .faq-answer {
    font-size: 30px;
  }
}
/* 移动端 */
@media (max-width: 768px) {
  .faq-section {
    padding-top: 5vw;
    padding-bottom: 15vw;
  }
  .faq-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .faq-item {
    padding-top: 20px;
  }
  .faq-question {
    font-size: 16px;
  }
  .faq-answer {
    font-size: 16px;
  }
}
