.header-v2 {
  display: block;
  box-sizing: border-box;
  min-width: 1440px;
}
.header-v2 .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  height: 830px;
  padding: 140px 180px 0;
  text-align: center;
  align-items: stretch;
  flex-shrink: 0;
  margin: 0 auto;
  margin-top: 3%;
  box-sizing: border-box;
  /* background-color: #5195E9; */
}

.header-v2-left {
  position: relative;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  /* background-color: red; */
}
.header-v2-pc-reward-banner {
  position: absolute;
  margin-left: 180px;
  top: 10px;
  width: 380px;
  height: auto;
  z-index: 20;
}
.header-v2-left h1 {
  font-size: 60px;
  color: #470b14;
  margin: 0;
}
.header-v2-left p {
  font-size: 30px;
  margin: 10px 0;
  color: #49454F;
  line-height: 1.5;
  text-align: left;
}
.header-v2-download-button {
  width: 338px;
  height: 90px;
  margin-top: 30px;
  max-width: 35vw;
  max-height: 15vh;
  min-height: 90px;
  font-size: clamp(10px, 30px, 30px); 
  box-shadow: 0px 10px 10px rgba(99, 50, 57, 0.25);
}

.header-v2-left
.header-v2-download-button-desc {
  font-size: 20px;
  margin-top: 20px;
}

.header-v2-web-button {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  background-color: #F3AC40;
  padding: 0 75px;
}
.header-v2-web-button img {
  width: 28px;
}

.header-v2-right {
  position: relative;
  max-width: 50%;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  margin-left: 50px;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  /* background-color: green; */
}
.header-v2-video-container {
  position: relative;
  top: 170px;
  left: -40px;
  width: 580px;
  height: 362px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}
.header-v2-video-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 10;
}
.header-v2-video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 560px;
  aspect-ratio: 16 / 10;
  border-radius: 10px;
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.header-v2-video-player iframe {
  width: 100%;
  height: 100%;
}

.header-v2-wing {
  position: absolute;
  width: auto;
  height: auto;
  top: 100px;
  left: 50%;
  z-index: -1;
}
