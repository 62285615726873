.app-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (min-width: 769px) {
  .app-header {
    padding-top: 10vh;
    height: 77vh;
  }
}
@media (max-width: 768px) {
  /* mobile */  
  .app-header {
    height: 100vh;
  }
}
.app-title {
  color: #470B14;
  margin: 10px 0;
  font-weight: 700;
  font-size: 60px;
}
@media (max-width: 768px) {
  .app-title {
    font-size: 40px;
  }
}
.app-subtitle {
  color: #49454F;
  margin-top: 0;
  margin-bottom: 3vw;
  font-weight: 700;
  font-size: 50px;
}
@media (max-width: 768px) {
  /* 移动端 */
  .app-subtitle {
    /* 动态字体大小 */
    font-size: clamp(20px, 30px, 30px); 
  }
}

.reward-banner-pc {
  display: block;
  position: absolute;
  top: 330px; 
  margin-left: 530px;
  width: 300px; 
  z-index: 1;
}
.reward-banner-mobile {
  display: none;
  margin-top: 10px;
  width: 250px; 
}
@media (max-width: 768px) {
  /* 移动端 */
  .reward-banner-pc {
    display: none;
  }
  .reward-banner-mobile {
    display: block;
  }
}


.download-buttons-container {
  display: flex;
  justify-content: center;
  gap: 3vw; 
  margin-bottom: 20px;
}
@media (min-width: 769px) {
  /* PC端 */
  .header-download-button {
    width: 420px;
    height: 120px;
    max-width: 35vw;
    max-height: 15vh;
    min-height: 90px;
    padding-right: 50px;
    box-shadow: 0px 10px 10px rgba(99, 50, 57, 0.25);
    font-size: clamp(10px, 30px, 30px); 
  }
  .header-download-icon {
    width: 58px;
    height: 58px;
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  /* 移动端 */
  .header-download-button {
    /* margin-top: 8vh; */
    margin-top: 4px;
    font-size: clamp(20px, 28px, 30px);
    max-width: 70vw;
    width: 280px;
    height: 80px;
    box-shadow: 0px 10px 10px rgba(99, 50, 57, 0.25);
  }
  .header-download-icon {
    width: 39px;
    height: 39px;
    margin-right: 20px;
  }
}

.download-button-desc {
  color: #49454F;
  font-size: 20px;
}
@media (max-width: 768px) {
  /* 移动端 */
  .download-button-desc {
    margin-top: 5%;
    margin-bottom: 10%;
    font-size: clamp(10px, 5vw, 20px); 
  }
}
