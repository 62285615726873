.header-v2-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 800px;
  /* background-color: red; */
  overflow: hidden;
}
.header-v2-mobile .container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  /* box-sizing: border-box; */
  /* overflow: hidden; */
  position: relative;
  /* padding-bottom: 30px; */
}

.header-v2-mobile-wing {
  position: absolute;
  top: 0; 
  width: 318px; 
  z-index: -1; 
}
.header-v2-mobile-title {
  position: relative;
  font-size: 40px;
  font-weight: 700;
  color: #470b14;
  white-space: nowrap;
  margin: 0 auto;
}
.header-v2-mobile-desc {
  position: relative;
  font-size: 20px;
  margin: 5px auto;
  color: #49454F;
  line-height: 1.5;
}

.header-v2-video-container-mobile {
  position: relative;
  width: 90%;
  aspect-ratio: 16 / 9;
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 40px; */
  /* background-color: red; */
}
.header-v2-video-bg-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 10;
}
.header-v2-video-player-mobile {
  position: relative;
  width: 95%;
  aspect-ratio: 16 / 9;
  border-radius: 9px;
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.2); */
}
.header-v2-video-player-mobile iframe {
  width: 100%;
  height: 100%;
}

.header-v2-mobile-reward-banner {
  position: relative;
  width: 250px; 
  max-width: 60vw;
  height: auto;
}
.header-v2-mobile-download-button {
  position: relative;
  font-size: clamp(20px, 28px, 30px);
  max-width: 70vw;
  width: 280px;
  height: 80px;
  box-shadow: 0px 10px 10px rgba(99, 50, 57, 0.25);
  background-color: #F3AC40;
}