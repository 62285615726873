.advantages-section {
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-color: #E4DDD7;
}
.advantages-section .container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  gap: 10px;
  /* background-color: #5195E9; */
}
@media (min-width: 768px) {
  .advantages-section {
    min-width: 1440px;
  }
  .advantages-section .container {
    width: 1440px;
    padding: 120px 0; 
    text-align: center;
  }
}
@media (max-width: 768px) {
  .advantages-section .container {
    padding: 50px 0; 
  }
}


.advantages-title {
  width: 100%;
  color: #49454F;
  margin-bottom: 30px;
  font-size: 50px;
  text-align: center;
  font-weight: 700;
}
.advantages-image-container {
  text-align: center;
  justify-content: center;
}
.advantages-image {
  width: 100%; 
  height: auto;
  object-fit: contain;
}
.advantages-image-container p {
  margin-top: 15px;
  color: #848484;
  font-size: 30px;
}

/* PC */
@media (min-width: 768px) {
  .advantages-image-container {
    width: 35%;
    max-width: 360px;
    height: 360px;
    margin: 30px;
    margin-bottom: 100px;
  }
  .advantage-1 { order: 1; }
  .advantage-2 { order: 2; }
  .advantage-3 { order: 3; }
  .advantage-4 { order: 4; }
  .advantage-5 { order: 5; }
  .advantage-6 { order: 6; }

  .advantages-text .advantages-dual-part-1,
  .advantages-text .advantages-dual-part-2 {
    display: inline;
  }
  .advantages-text .advantages-lang-part-1,
  .advantages-text .advantages-lang-part-2 {
    display: block;
  }
}

/* 移动端 */
@media (max-width: 768px) {
  .advantages-title {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 3vw;
  }
  .advantages-image-container {
    max-width: 200px;
    max-height: 200px;
    margin: 10px;
    margin-bottom: 10px;
  }
  /* .advantage-2 {
    width: 46vw;
    max-width: none;
    margin-bottom: 50px;
    margin-left: 20vw;
    margin-right: 20vw;
  } */
  .advantage-1,
  .advantage-2,
  .advantage-3,
  .advantage-4,
  .advantage-5,
  .advantage-6 {
    flex: 0 1 35vw; 
  }
  .advantage-1 { order: 3; }
  .advantage-2 { order: 1; }
  .advantage-3 { order: 4; }
  .advantage-4 { order: 5; }
  .advantage-5 { order: 2; }
  .advantage-6 { order: 6; }

  .advantages-image-container p {
    font-size: 15px;
    word-wrap: break-word;
  }

  .advantages-text .advantages-dual-part-1,
  .advantages-text .advantages-dual-part-2 {
    display: block;
  }
  .advantages-text .advantages-lang-part-1,
  .advantages-text .advantages-lang-part-2 {
    display: block;
  }
}