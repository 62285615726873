body {
  background-color: #E4DDD7;
}

.app {
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.alert-button-cancel {
  background-color: white;
  color: #470B14 !important;
  font-weight: bold;
  box-shadow: inset 0 0 0 1px #470B14;
  border-radius: 5px; 
  color: white;
  border-style: solid;
  min-width: 100px;
}

.alert-button-confirm {
  background-color: #FEE032 !important; 
  color: #470B14 !important;
  font-weight: bold;
  border-radius: 5px; 
  margin-left: 20px; 
  min-width: 100px;
}

.alert-button-single {
  background-color: #FEE032 !important; 
  color: #470B14 !important;
  font-weight: bold;
  border-radius: 5px; 
  min-width: 100px;
}

footer {
  margin-top: 0;
  text-align: center;
}
